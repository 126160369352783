#team {
  padding: 10px 0;
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  max-width: 240px;
}
#team .thumbnail {
  background:transparent;
  border: 0;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: rgb(10, 10, 10);
}

#team h2{
  word-wrap: break-word;
}

.text-left{
  padding-top: 10px;
  padding-bottom: 0px;
}

  .text-centered{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
  }
  .team > p {
      margin-bottom: 10px;
  }
  .responsive1{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

 ul {
    list-style-type: none;
  }

  @media screen and (max-width: 1200px) {
    .responsive1{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    #team .team-img {
      max-width: 240px;
    }
    .caption{
      margin-bottom: 10px;
    }
    .caption p {
      margin-top: 0px;
    }
  
  }

    img {
        filter: drop-shadow(8px 8px 10px gray);
      }
      .img-responsive {
        width: 100%;
        height: auto;
      }
    