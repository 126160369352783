.item {
    width: 300px;
    padding: 1rem;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .item > img {
    width: 100%;
    height: 170px;
    object-fit: contain;
    border-radius: 8px;
  }

  .info_link{
    text-decoration: none;
  }
  .info_link > a:link, a:visited,  a:hover, a:active
  {
      color: #000000;
      text-decoration: none;
  }
  .item_info > p {
    margin-bottom: 8px;
    margin-top: 10px;
  }
  
  .info_price {
    font-weight: bold;
  }
  
  .info_name {
    font-size: 1rem;
    overflow: hidden;
  }
  
  .info_description {
    font-size: 0.8rem;
  }
  
  .info_button {
    display: block;
    text-decoration: none;
    text-align: center;
    color: #171717;
    width: 100%;
    padding: 8px 16px;
    background-color: #f4f4f4;
    border: 1px solid #171717;
    font-size: 1rem;
  }
  
  .info_button:hover {
    background: #171717;
    color: #f4f4f4;
  }
  
  @media (max-width: 1232px) {
    .item {
      width: 360px;
    }
  }
  
  @media (max-width: 1115px) {
    .item {
      width: 330px;
    }
  }
  
  @media (max-width: 1028px) {
    .item {
      width: 300px;
    }
  }
  
  @media (max-width: 950px) {
    .item {
      width: 400px;
    }
  }
  
  @media (max-width: 830px) {
    .item {
      width: 330px;
    }
  }
  
  @media (max-width: 700px) {
    .item {
      width: 290px;
    }
  }
  
  @media (max-width: 630px) {
    .item {
      width: 450px;
    }
  }
  
  @media (max-width: 500px) {
    .item {
      width: 350px;
    }
  }
  
  @media (max-width: 400px) {
    .item {
      width: 300px;
    }
  }