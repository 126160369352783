.chatbot_box{
    height: 500px; 
    width: 500px;
    border: 3px solid black; 
    border-radius: 7px;
    background-color:  rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px)
}
.chatbot_inter{
    height: 440px;
    width: 100%;
    overflow: auto;
} 

.message{
    margin: 0;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 5px;
    font-size: 1rem
}
   .card_responsive{
       width: 300px;
   }
.ant-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-list-item-meta-content {
    max-width: 500px;
  }
  
  .ant-list-item-meta-title {
    font-size: 20px !important;
    font-weight: 700;
  }
  
  .ant-list-item-meta-description {
    display: flex;
    color: rgb(0, 0, 0) !important;
    font-weight: 500;
  }
 

  @media screen and (max-width: 1100px) {
      .card_responsive{
            max-width: 200px;
      }
      .chatbot_box{
        height: 200px; 
        width: 200px;
        border: 3px solid black; 
        border-radius: 7px;
        background-color:  rgba(255, 255, 255, .15);
        backdrop-filter: blur(5px)
    }
    .chatbot_inter{
        height: 160px;
        width: 100%;
    }
    .message{
        margin: 0;
        width: 100%;
        height: 30px;
        border-radius: 4px;
        padding: 5px;
        font-size: 1rem
    }
  }