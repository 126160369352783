.cartitem {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 5fr 4fr 4fr 1fr 1fr;
    gap: 8px;
    background: #fff;
    border-radius: 2px;
    place-items: center;
    margin-bottom: 8px;
    position: relative;
  }
  
  .cartitemimage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .cartItem__name {
    text-decoration: none;
    color: #171717;
    font-weight: 600;
  }
  
  .cartItem__name:hover {
    color: #3258aa;
  }
  
  .cartitem__price{
    font-weight: 600;
  }
  .cartItem__select {
    padding: 10px 17px;
  }
  
  .cartItem__deleteBtn {
    padding: 10px 17px;
    color: rgb(180, 7, 7);

    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  
  .cartItem__deleteBtn:hover,
  .carItem__deleteBtn:active,
  .carItem__deleteBtn:focus {

    color: rgb(184, 13, 13);
    transform: scale(1.2);
  }
  

  
  @media (max-width: 700px) {
    .cartItem__name {
      font-size: 13px;
      padding-left: 10px;
      padding: 5px;
      font-weight: 600;
      text-align: center;
    }
  
    .cartItem__select,
    .cartItem__deleteBtn {
        height: auto;
        width: auto;
        padding: 1px
    }
  
    .cartitem__price {
      font-size: 13px;
      padding: 5px;
      font-weight: 600;
      text-align: center;
    }
  }