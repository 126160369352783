.Product {
    max-width: 1300px;
    margin: 1rem auto;
  }
.Product__products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media (max-width: 1232px) {
    .Product__products{
      grid-template-columns: repeat(3, auto);
    }
  }
  
  @media (max-width: 950px) {
    .Product__products {
      grid-template-columns: repeat(2, auto);
    }
  }
  
  @media (max-width: 630px) {
    .Product__products{
      grid-template-columns: 1fr;
    }
  }