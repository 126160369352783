.footer_address{
    max-width: 500px;
}
.copyright{
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    width: 100%;
    background-color: #0e0d0d;
    color: #fefefe;
}
.footer_privacy{
    padding-left: 30px;
    padding-right: 30px;

}
.footer_privacy > li{
    list-style: none;
    padding-top: 10px;
}
.footer_up{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: sticky;
     bottom: 0;
     padding: 10px;
     width: 100%;
}
.footer_support> li{
    list-style: none;
    padding-top: 10px;
}
nav{
    background-color: #343a40;
    color: #fefefe;
}
.footer_main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: sticky;
     bottom: 0;
     padding: 10px;
     width: 100%;
}
.social {
    border-top: 1px solid rgb(255, 255, 255);
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom:15px;;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
.social ul li {
    display: inline-block;
    margin: 0 20px;
  }
.social i.fa {
    font-size: 22px;
    width: 48px;
    height: 48px;
    padding: 12px 0;
    border: 2px solid rgb(241, 238, 238);
    color: rgb(255, 254, 254);
    border-radius: 50%;
    transition: all 0.3s;
  }
.social i.fa:hover {
    color: #608dfd;
    background: #333;
  } 
  @media screen and (max-width: 1100px) {
    .footer_up{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        position: sticky;
         bottom: 0;
         padding: 10px;
         width: 100%;
    }
    .footer_privacy{
        padding: 0;
        padding-bottom: 10px;
    
    }
}
    @media screen and (max-width: 800px){
    .copyright{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }