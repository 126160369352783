*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body
{
    min-height: 100%;
    background-color: #f6f9fa;
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.col{
    padding: 0;
}
.col-xs-12{
    padding: 0;
}
.col-md-6{
    padding: 0;
}

.row{
    margin: 0;
  }
  

.container{
    padding-right: 0px;
}