.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(59, 58, 58);
  }
  
  .image {
    width: 1000px;
    height: 600px;
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  @media screen and (max-width: 800px) {
    .image {
          width: 100%;
          height: 100%;
          z-index: -10;
        }
        .slider{
          width: auto;
          height: auto;
         padding-top: 40px;
        }
    }
    @media screen and (max-width: 500px) {
      .image {
          width: 100%;
          height: 100%;
          z-index: -10;
          overflow: auto;
        }
        .slider{
          width: auto;
          height: auto;
          overflow: auto;
          padding-top: 20px;
        }
      }